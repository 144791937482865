import RecentContactMessage from 'Common/Models/recentcontact-message.model';
import ContactBody from 'Common/Models/contact-body.model';
import {
    MessageDirectionEnum,
    MessageTypeEnum,
} from 'Common/Enums/message.enum';
import { ContactTypeEnum, FilterTypeEnum } from 'Common/Enums/contact.enum';
import RecentContactResponse from '../Models/recentcontactresponse.model';
import RecentContactModel from '../Models/recentcontacts.model';

export default function modifyRecentContactResponse(
    recentContact: RecentContactResponse,
    unAnswered?: any,
    unRead?: any,
    messageType?: MessageTypeEnum
) {
    const message = {
        userId: recentContact.userId,
        lastMessageContent: recentContact.lastMessageContent,
        lastMessageId: recentContact.lastMessageId,
        lastMessageContentType: recentContact.lastMessageContentType,
        connectorSourceId: recentContact.connectorSourceId,
        lastMessageTimeStamp: recentContact.lastMessageTimeStamp,
        messageDirection: recentContact.messageDirection,
        lastMessagePhoneNumber: recentContact.contactPhoneNumber,
        lastContactUserId: recentContact.lastContactUserId,
        lastContactUserName: recentContact.lastContactUserName,
        isTransferred: recentContact.isTransferred,
        recentContactTimeStamp: recentContact.recentContactTimeStamp,
        messageType,
    } as RecentContactMessage;
    const contact = {
        contactId: recentContact.contactId,
        contactType: recentContact.contactType,
        contactDisplayName: recentContact.contactDisplayName,
        contactPhotoURL: recentContact.contactPhotoURL,
        isContactSystemUser: recentContact.isContactSystemUser,
        opportunityInstanceId: recentContact.opportunityInstanceId,
        opportunityEventCode: recentContact.opportunityEventCode,
        opportunityInstanceName: recentContact.opportunityInstanceName ?? '',
        filterType: recentContact.filterType,
        orgCode: `${recentContact.orgCode}`,
        unAnswered,
        unRead,
        status: recentContact.status,
        lastContactUserName: recentContact.lastContactUserName,
        // opportunityEventName: recentContact.opportunityEventName,
    } as ContactBody;
    const unreadMessages = recentContact.unreadMessageCount;
    const { readPointer } = recentContact;
    return {
        message,
        contact,
        unreadMessages,
        readPointer,
    } as RecentContactModel;
}

export function messageToRecentContactConverter(MessageHistoryItem: any) {
    const fromLead = MessageHistoryItem.from.type === ContactTypeEnum.Lead;
    const message = {
        userId: '',
        lastMessageContent: MessageHistoryItem.message.content,
        lastMessageId: MessageHistoryItem.message.id,
        lastMessageContentType: 0, // check me
        connectorSourceId: MessageHistoryItem.message.connectorSourceId,
        lastMessageTimeStamp: MessageHistoryItem.message.timestamp,
        messageDirection: fromLead
            ? MessageDirectionEnum.inbound
            : MessageDirectionEnum.outbound, // check me
        lastMessagePhoneNumber: fromLead
            ? MessageHistoryItem.from.field
            : MessageHistoryItem.to.field, // check me
        lastContactUserId: fromLead
            ? MessageHistoryItem.to.id
            : MessageHistoryItem.from.id,
        lastContactUserName: '',
        isTransferred: false,
        recentContactTimeStamp: MessageHistoryItem.message.timestamp,
    } as RecentContactMessage;
    const contact = {
        contactId: fromLead
            ? MessageHistoryItem.from.id
            : MessageHistoryItem.to.id,
        contactType: ContactTypeEnum.Lead,
        contactDisplayName: fromLead
            ? (MessageHistoryItem?.from?.displayName ??
              MessageHistoryItem?.from?.field)
            : (MessageHistoryItem?.to?.displayName ??
              MessageHistoryItem?.to?.field),
        contactPhotoURL: '',
        isContactSystemUser: false,
        opportunityInstanceId: MessageHistoryItem.opportunityInstanceId,
        opportunityEventCode: MessageHistoryItem.opportunityEventCode,
        opportunityInstanceName: '',
        filterType: FilterTypeEnum.None,
        orgCode: MessageHistoryItem.orgCode,
        status: 0,
        lastContactUserName: '',
        // opportunityEventName: recentContact.opportunityEventName,
    } as ContactBody;
    const unreadMessages = 0;
    const readPointer = '';
    return {
        message,
        contact,
        unreadMessages,
        readPointer,
    } as RecentContactModel;
}
