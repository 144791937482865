import MXCache from 'Common/CacheManager/MXCache';
import {
    FilterTypeEnum,
    RCChatStatusEnum,
    TimeIntervalEnum,
} from 'Common/Enums/contact.enum';
import MXLogger from 'Common/MXLogger';
import {
    EventDetails,
    FilterState,
    OpportunityFilterType,
    myInboxChatCounts,
} from 'Common/Models/Opportunity/opportunity.model';
import { TemplateMessageFilter } from 'Common/Models/authresponse.model';
import MessageHistoryItem from 'Common/Models/message-history-item.model';
import RecentContactResponse from 'Common/Models/recentcontactresponse.model';
import RecentContactModel from 'Common/Models/recentcontacts.model';
import modifyRecentContactResponse, {
    messageToRecentContactConverter,
} from 'Common/Utils/Modifiers';
import { AppTypeEnum } from 'Context/current-app.context';
import MXEventEmitter from 'Services/MXEventEmitter';
import API from '../API';

type ParamsType = {
    filterType: FilterTypeEnum;
    eventCodes: string[];
    timeInterval: TimeIntervalEnum;
    waitTime: number;
    currentTimestamp: number;
    nextFrom: number;
    pageSize: number;
    appView: number;
    rcChatStatus?: RCChatStatusEnum;
    userIds?: string[];
    userNames?: string[];
    customDateInterval?: { startDate: string; endDate: string };
    startTimestamp?: number;
    endTimestamp?: number;
};

const getRecentContactAPIEndpoint = {
    [FilterTypeEnum.All]: 'all-chat',
    [FilterTypeEnum.AllChats]: 'team-all',
    [FilterTypeEnum.AllUnanswered]: 'team-unanswered',
    [FilterTypeEnum.Focused]: 'my-chat',
    [FilterTypeEnum.None]: '',
    [FilterTypeEnum.Unanswered]: 'my-unanswered',
    [FilterTypeEnum.Unassigned]: 'team-unassigned',
    [FilterTypeEnum.Unread]: 'my-unread',
};

const getLeadSearchAPIEndpoint = {
    [FilterTypeEnum.All]: 'all',
    [FilterTypeEnum.AllChats]: 'all',
    [FilterTypeEnum.AllUnanswered]: 'unanswered',
    [FilterTypeEnum.Focused]: 'all',
    [FilterTypeEnum.None]: '',
    [FilterTypeEnum.Unanswered]: 'unanswered',
    [FilterTypeEnum.Unassigned]: 'unassigned',
    [FilterTypeEnum.Unread]: 'unread',
};

export default class RecentContactService extends API {
    static baseUrl = process.env.REACT_APP_CLIENT_SERVICE_URL || '';
    // static baseUrl = "http://localhost:9000/";

    static abortControllers = {
        getLeadById: {
            ac: new AbortController(),
            lastParams: '',
        },
        getOppFilter: {
            ac: new AbortController(),
            lastParams: '',
        },
        getFullscreenTeamChatCount: {
            ac: new AbortController(),
            lastParams: '',
        },
        getFullscreenMyChatCount: {
            ac: new AbortController(),
            lastParams: '',
        },
        getEventDetails: {
            ac: new AbortController(),
            lastParams: '',
        },
        getCarter: {
            ac: new AbortController(),
            lastParams: '',
        },
        getRecentContact: {
            ac: new AbortController(),
            lastParams: '',
        },
        setUserPref: {
            ac: new AbortController(),
            lastParams: '',
        },
    };

    constructor() {
        super(RecentContactService.baseUrl);
    }

    async getUnreadChatCount(): Promise<{
        totalUnreadChatCount: number;
        isCarterUnread: boolean;
    }> {
        try {
            const res = this.Responsify<{
                totalUnreadChatCount: number;
                isCarterUnread: boolean;
            }>(await this.instance.get('/user/unreadCount'));
            return res;
        } catch (err) {
            console.log({ err });
            return { totalUnreadChatCount: 0, isCarterUnread: false };
        }
    }

    async getRecentContacts(
        appType: AppTypeEnum,
        params: ParamsType
    ): Promise<RecentContactModel[]> {
        let endpoint =
            appType !== AppTypeEnum.Fullscreen ? 'widget' : 'full-screen';

        endpoint = `${endpoint}/inbox/${
            getRecentContactAPIEndpoint[params.filterType]
        }`;

        RecentContactService.abortControllers.getRecentContact.ac.abort();
        RecentContactService.abortControllers.getRecentContact = {
            ac: new AbortController(),
            lastParams: '',
        };

        const adminView =
            MXCache.GetCacheItem(MXCache.IsAdminViewkey)?.adminView ?? false;
        endpoint += `?adminView=${adminView}`;
        try {
            const { customDateInterval, userNames, ...recentContactsParams } =
                params;
            const res = this.Responsify<RecentContactResponse[]>(
                await this.instance.post(endpoint, recentContactsParams, {
                    signal: RecentContactService.abortControllers
                        .getRecentContact.ac.signal,
                })
            );

            const modifiedData = res.map((recentContact) =>
                modifyRecentContactResponse(recentContact)
            );
            MXCache.SetCacheItem(MXCache.PreviousSelectedFilters, params, 600);
            return modifiedData.map((rc) => new RecentContactModel(rc));
        } catch (err) {
            // this cactch block to load the recent contact from cache even though the recent contact api failure's
            const previouslyFetchedRecentContacts = MXCache.GetCacheItem(
                MXCache.RecentContacts
            );
            if (params.nextFrom !== 0 || !previouslyFetchedRecentContacts) {
                return [];
            }
            MXEventEmitter.emit('APPLICATION_SERVICE_FAILURE', {
                ID: 'LOAD_PREVIOUS_SELECTED_USER_PREFERENCES',
            });

            const previouslyFetchedRecentContactsLength =
                previouslyFetchedRecentContacts?.length;
            if (previouslyFetchedRecentContactsLength) {
                if (
                    previouslyFetchedRecentContacts[0].contact
                        .opportunityEventCode === '100_C'
                ) {
                    const recentContacts =
                        previouslyFetchedRecentContacts.slice(
                            1,
                            previouslyFetchedRecentContactsLength
                        );

                    return recentContacts;
                }
                return previouslyFetchedRecentContacts;
            }

            return [];
        }
    }

    async getCarterContacts(
        isSystemUserOnly: boolean,
        params?: string
    ): Promise<RecentContactModel[]> {
        RecentContactService.abortControllers.getCarter.ac.abort();
        RecentContactService.abortControllers.getCarter = {
            ac: new AbortController(),
            lastParams: '',
        };
        try {
            let res: RecentContactResponse[];
            let carter: RecentContactResponse[] | null = null;
            if (isSystemUserOnly) {
                carter = MXCache.GetCacheItem(
                    MXCache.Carter
                ) as RecentContactResponse[];
            }
            if (carter && carter[0]?.readPointer) {
                res = carter;
            } else {
                let suffix = 'recentContacts/carter';
                if (params) {
                    suffix = `${suffix}?${params}`;
                }

                res = await this.Responsify<RecentContactResponse[]>(
                    await this.instance.get(suffix, {
                        signal: RecentContactService.abortControllers.getCarter
                            .ac.signal,
                    })
                );
                if (isSystemUserOnly) {
                    if (res === undefined || res === null) {
                        MXLogger.error(new Error('Unable to fetch Carter'));
                    }
                }
            }
            MXCache.SetCacheItem(MXCache.Carter, res, 600);
            const modifiedData = res.map((recentContact) =>
                modifyRecentContactResponse(recentContact)
            );
            return modifiedData as RecentContactModel[];
        } catch (err) {
            const carter = MXCache.GetCacheItem(
                MXCache.Carter
            ) as RecentContactResponse[];
            if (carter && carter[0]?.readPointer) {
                const modifiedDataCarterResponse = carter.map((recentContact) =>
                    modifyRecentContactResponse(recentContact)
                );
                return modifiedDataCarterResponse as RecentContactModel[];
            }
            return [];
        }
    }

    async searchChats(
        searchText: string,
        filterType: FilterTypeEnum,
        nextFrom: number
    ): Promise<RecentContactModel[]> {
        try {
            const inboxName =
                filterType < FilterTypeEnum.AllChats
                    ? 'my-inbox'
                    : 'team-inbox';
            const res = this.Responsify<MessageHistoryItem[]>(
                await this.instance.post(`${inboxName}/all/search/message`, {
                    nextFrom,
                    pageSize: 5,
                    searchText: encodeURIComponent(searchText.toLowerCase()),
                })
            );
            const modifiedData = res.map((message) =>
                messageToRecentContactConverter(message)
            );
            return modifiedData as RecentContactModel[];
        } catch (e) {
            return [] as RecentContactModel[];
        }
    }

    async searchChatCount(
        searchText: string,
        filterType: FilterTypeEnum
    ): Promise<number> {
        // return 10;
        try {
            const inboxName =
                filterType < FilterTypeEnum.AllChats
                    ? 'my-inbox'
                    : 'team-inbox';
            const res = this.Responsify<{ count: number }>(
                await this.instance.get(
                    `${inboxName}/all/search/count/message?searchText=${encodeURIComponent(
                        searchText.toLowerCase()
                    )}&searchType=3`
                )
            );
            return res?.count ?? 0;
        } catch (err) {
            console.log(err);
            return 0;
        }
    }

    async searchLeadOrOpportunityCount(
        searchText: string,
        filterType: FilterTypeEnum,
        searchType: 1 | 2
    ): Promise<number> {
        const inboxName =
            filterType < FilterTypeEnum.AllChats ? 'my-inbox' : 'team-inbox';
        const filterName = getLeadSearchAPIEndpoint[filterType];
        try {
            const res = this.Responsify<{ count: number }>(
                await this.instance.get(
                    `${inboxName}/${filterName}/search/count/recent-contact?searchText=${encodeURIComponent(
                        searchText.toLowerCase()
                    )}&searchType=${searchType}`
                )
            );
            return res?.count ?? 0;
        } catch (err) {
            console.log(err);
            return 0;
        }
    }

    async searchLead(
        searchText: string,
        filterType: FilterTypeEnum,
        nextFrom: number
    ): Promise<RecentContactModel[]> {
        const inboxName =
            filterType < FilterTypeEnum.AllChats ? 'my-inbox' : 'team-inbox';
        const filterName = getLeadSearchAPIEndpoint[filterType];
        try {
            const res = this.Responsify<RecentContactResponse[]>(
                await this.instance.post(
                    `${inboxName}/${filterName}/search/recent-contact`,
                    {
                        nextFrom,
                        pageSize: 5,
                        searchType: 1,
                        searchText: encodeURIComponent(
                            searchText.toLowerCase()
                        ),
                    }
                )
            );
            const modifiedData = res.map((rc) =>
                modifyRecentContactResponse(rc)
            );
            return modifiedData as RecentContactModel[];
        } catch (e) {
            return [];
        }
    }

    async searchLeadPlatform(searchText: string): Promise<any[]> {
        try {
            return this.Responsify<any[]>(
                await this.instance.get(
                    `lead/search?searchText=${encodeURIComponent(
                        searchText.toLowerCase()
                    )}`
                )
            );
        } catch (e) {
            return [];
        }
    }

    async searchOpportunities(
        searchText: string,
        filterType: FilterTypeEnum,
        nextFrom: number
    ): Promise<RecentContactModel[]> {
        const inboxName =
            filterType < FilterTypeEnum.AllChats ? 'my-inbox' : 'team-inbox';
        const filterName = getLeadSearchAPIEndpoint[filterType];
        try {
            const res = this.Responsify<RecentContactResponse[]>(
                await this.instance.post(
                    `${inboxName}/${filterName}/search/recent-contact`,
                    {
                        nextFrom,
                        pageSize: 5,
                        searchType: 2,
                        searchText: encodeURIComponent(
                            searchText.toLowerCase()
                        ),
                    }
                )
            );

            const modifiedData = res.map((rc) =>
                modifyRecentContactResponse(rc)
            );
            return modifiedData as RecentContactModel[];
        } catch (e) {
            return [];
        }
    }

    async getOpportunityFilters(
        appType: AppTypeEnum,
        params: {
            eventCodes: string[];
            currentTimestamp: number;
            timeInterval?: TimeIntervalEnum | string;
            waitTime?: number | string;
            startTimestamp?: number;
            endTimestamp?: number;
        },
        force = false
    ): Promise<{
        eventDetails: OpportunityFilterType[];
        myInbox: myInboxChatCounts;
        force?: boolean;
    }> {
        RecentContactService.abortControllers.getOppFilter.ac.abort();
        RecentContactService.abortControllers.getOppFilter = {
            ac: new AbortController(),
            lastParams: '',
        };
        if (!force) {
            const oppFilterOptions = MXCache.GetCacheItem(
                MXCache.opportunityFilterOptions
            );
            if (oppFilterOptions) {
                return oppFilterOptions;
            }
        }
        let suffix =
            appType === AppTypeEnum.Fullscreen ? '/full-screen' : '/widget';

        suffix += '/opportunity-filter-count';

        const response = this.Responsify<{
            eventDetails: OpportunityFilterType[];
            myInbox: myInboxChatCounts;
        }>(
            await this.instance.post(suffix, params, {
                signal: RecentContactService.abortControllers.getOppFilter.ac
                    .signal,
            })
        );

        if (response.eventDetails.length) {
            MXCache.SetCacheItem(MXCache.opportunityFilterOptions, response);
        }

        return response;
    }

    async getFullscreenMyChatCount(
        params: {
            eventCodes: string[];
            currentTimestamp: number;
            timeInterval?: TimeIntervalEnum | string;
            waitTime?: number | string;
            startTimestamp?: number;
            endTimestamp?: number;
            userIds?: string[] | null;
            rcChatStatus?: RCChatStatusEnum;
        },
        force = false
    ): Promise<{
        myInbox: myInboxChatCounts;
        force?: boolean;
    }> {
        RecentContactService.abortControllers.getFullscreenMyChatCount.ac.abort();
        RecentContactService.abortControllers.getFullscreenMyChatCount = {
            ac: new AbortController(),
            lastParams: '',
        };

        if (!force) {
            const oppFilterOptions = MXCache.GetCacheItem(
                MXCache.fs_opportunityFilterOptions
            );
            if (oppFilterOptions) {
                return oppFilterOptions;
            }
        }

        const suffix = '/full-screen' + '/my-opportunity-filter-count';

        const response = this.Responsify<{
            myInbox: myInboxChatCounts;
        }>(
            await this.instance.post(suffix, params, {
                signal: RecentContactService.abortControllers.getOppFilter.ac
                    .signal,
            })
        );

        if (response.myInbox.hasOwnProperty('allChatCount')) {
            MXCache.SetCacheItem(MXCache.fs_opportunityFilterOptions, response);
        }

        return response;
    }

    async getFullscreenTeamChatCount(params: {
        eventCodes: string[];
        currentTimestamp: number;
        timeInterval?: TimeIntervalEnum | string;
        waitTime?: number | string;
        startTimestamp?: number;
        endTimestamp?: number;
        userIds?: string[] | null;
        rcChatStatus?: RCChatStatusEnum;
    }): Promise<{
        teamInbox: myInboxChatCounts;
    }> {
        RecentContactService.abortControllers.getFullscreenTeamChatCount.ac.abort();
        RecentContactService.abortControllers.getFullscreenTeamChatCount = {
            ac: new AbortController(),
            lastParams: '',
        };
        const suffix = '/full-screen' + '/team-opportunity-filter-count';

        const response = this.Responsify<{
            teamInbox: myInboxChatCounts;
        }>(
            await this.instance.post(suffix, params, {
                signal: RecentContactService.abortControllers.getOppFilter.ac
                    .signal,
            })
        );

        return response;
    }

    async getEventDetails(): Promise<any> {
        const eventDetails = MXCache.GetCacheItem(MXCache.fs_eventDetails);
        if (eventDetails) {
            return eventDetails;
        }
        const res = this.Responsify<EventDetails>(
            await this.instance.get('full-screen/event-details')
        );

        if (res) {
            MXCache.SetCacheItem(MXCache.fs_eventDetails, res);
        }

        return res;
    }

    async setUserPref(
        FilterData: FilterState,
        appType: AppTypeEnum,
        templateMessagesFilter?: TemplateMessageFilter[],
        enableNotifications?: {
            converse: boolean;
            carter: boolean;
        }
    ): Promise<any> {
        if (
            [
                FilterTypeEnum.AllChats,
                FilterTypeEnum.AllUnanswered,
                FilterTypeEnum.Unassigned,
            ].includes(FilterData.filterType)
        ) {
            return;
        }
        RecentContactService.abortControllers.setUserPref.ac.abort();
        RecentContactService.abortControllers.setUserPref = {
            ac: new AbortController(),
            lastParams: '',
        };
        const oppList = FilterData.eventDetails
            .filter((op) => op.isSelected)
            .map((item) => item.eventCode);
        const businessNumber = FilterData.selectedBusinessNumbers;
        return this.Responsify<any>(
            await this.instance.post(
                'user/preference',
                {
                    recentContactsFilter: {
                        widgetFilterType:
                            appType === AppTypeEnum.Fullscreen
                                ? (FilterData.widgetFilterType ??
                                  FilterTypeEnum.Focused)
                                : FilterData.filterType,
                        fullScreenFilterType:
                            appType === AppTypeEnum.Fullscreen
                                ? FilterData.filterType
                                : FilterData.fullScreenFilterType,
                        opportunityEventCode: oppList, // list of opportunityTypes
                        businessNumber,
                        timeInterval:
                            FilterData?.timeInterval !== TimeIntervalEnum.None
                                ? (FilterData?.timeInterval ?? null)
                                : null,
                        waitTime:
                            FilterData?.waitTime && FilterData.waitTime > 0
                                ? FilterData.waitTime
                                : null,
                    },
                    templateMessagesFilter: templateMessagesFilter || [],
                    enableNotifications,
                },
                {
                    signal: RecentContactService.abortControllers.setUserPref.ac
                        .signal,
                }
            )
        );
    }

    async IsAdminViewForFilters(): Promise<any> {
        // return true;
        let isAdminView = MXCache.GetCacheItem(MXCache.IsAdminViewkey) as any;
        if (isAdminView !== null && isAdminView !== undefined) {
            return isAdminView;
        }
        // isAdminView = this.Responsify<FilterStateResponse>(
        //     await new API("http://localhost:9000/", true, true).instance.get(
        //         "view"
        //     )
        // );
        isAdminView = this.Responsify<any>(await this.instance.get('view'));

        if (isAdminView === undefined || isAdminView === null) {
            MXLogger.error(new Error('Unable to fetch View Type'));
        } else {
            MXCache.SetCacheItem(MXCache.IsAdminViewkey, isAdminView, 600);

            return isAdminView;
        }
    }
}
