import MXCache from 'Common/CacheManager/MXCache';
import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import RecentContactService from 'Services/APIServices/recentcontact.service';
import { CurrentAppContext } from './current-app.context';
import { CurrentFilterContext } from './current-filter.context';
interface NotificationSoundContextType {
    isSoundEnabled: {
        carter: boolean;
        converse: boolean;
    };
    toggleSound: (type: 'carter' | 'converse') => void;
}
const NotificationSoundContext = createContext<NotificationSoundContextType>({
    isSoundEnabled: { carter: true, converse: true },
    toggleSound: () => {},
});
export const useNotificationSound = () => useContext(NotificationSoundContext);
export const NotificationSoundProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const { state: FilterData, dispatch: FilterDispatch } =
        useContext(CurrentFilterContext);
    const currentApp = useContext(CurrentAppContext);
    const [isSoundEnabled, setIsSoundEnabled] = useState({
        carter: true,
        converse: true,
    });
    const hasInitialized = useRef(false);
    const pollInterval = useRef<NodeJS.Timeout | null>(null);
    const retryCount = useRef(0);
    const MAX_RETRIES = 5;
    useEffect(() => {
        if (hasInitialized.current) return;
        const checkLocalStorage = () => {
            try {
                retryCount.current++;
                const enableNotifications = MXCache.GetCacheItem(
                    MXCache.EnableNotifications
                );
                if (enableNotifications) {
                    setIsSoundEnabled(enableNotifications);
                    if (pollInterval.current) {
                        clearInterval(pollInterval.current);
                        pollInterval.current = null;
                    }
                    hasInitialized.current = true;
                } else if (retryCount.current >= MAX_RETRIES) {
                    console.warn('Max retries reached for localStorage check');
                    if (pollInterval.current) {
                        clearInterval(pollInterval.current);
                        pollInterval.current = null;
                    }
                    hasInitialized.current = true;
                }
            } catch (error) {
                console.error('Error checking localStorage:', error);
                if (pollInterval.current) {
                    clearInterval(pollInterval.current);
                    pollInterval.current = null;
                }
            }
        };
        checkLocalStorage();
        if (!hasInitialized.current) {
            pollInterval.current = setInterval(checkLocalStorage, 1000);
        }
        return () => {
            if (pollInterval.current) {
                clearInterval(pollInterval.current);
                pollInterval.current = null;
            }
        };
    }, []);
    const toggleSound = (type: 'carter' | 'converse') => {
        setIsSoundEnabled((prev) => {
            const notificationSoundState = { ...prev, [type]: !prev[type] };
            MXCache.SetCacheItem(
                MXCache.EnableNotifications,
                notificationSoundState
            );
            const templateMessagesFilter = MXCache.GetCacheItem(
                MXCache.TemplateMessagesFilter
            );
            new RecentContactService().setUserPref(
                FilterData,
                currentApp.appType,
                templateMessagesFilter,
                notificationSoundState
            );
            return notificationSoundState;
        });
    };
    return (
        <NotificationSoundContext.Provider
            value={{ isSoundEnabled, toggleSound }}
        >
            {children}
        </NotificationSoundContext.Provider>
    );
};
