export enum MessageTypeEnum {
    None = 0,
    Converse = 1,
    SMS = 2,
    Notification = 3,
    WhatsApp = 4,
    WhatsAppBot = 5,
    WebBot = 6,
    Internal = 7,
    ExternalWebBot = 8,
    ActionTriggerBot = 9,
}

export enum DeliveryStatusEnum {
    Sending = 0,
    Sent = 1,
    Acknowledged = 2,
    Delivered = 3,
    Read = 4,
    Failed = 5,
    Unknown = 6,
}

export enum MessageDirectionEnum {
    inbound = 1,
    outbound = 2,
}

export enum NotificationType {
    Lead = 1,
    Task = 2,
    Activity = 3,
    Opportunity = 4,
}

export enum CTAType {
    None = 0,
    Link = 1,
}

export enum WhatsAppMessageTypeEnum {
    text = 1,
    image = 2,
    document = 3,
    video = 4,
    audio = 5,
}

export enum MessageViewTypeEnum {
    text = 'text',
    session = 'session',
    agentHandover = 'agent_handover',
    transferReceiver = 'transfer_receiver',
    transferInitiator = 'transfer_initiator',
    assignmentReceiver = 'assignment_receiver',
    assignmentInitiator = 'assignment_initiator',
    attachment = 'attachment',
    buttons = 'buttons',
    list = 'list',
    carter = 'carter',
    buttonTemplate = 'button_template',
    botJourneyStart = 'BOT_JOURNEY_START',
    botJourneyComplete = 'BOT_JOURNEY_COMPLETE',
    botJourneyExpire = 'BOT_JOURNEY_EXPIRE',
    botJourneyEnd = 'BOT_JOURNEY_END',
}

export enum EntityTypeEnum {
    LEAD = 1,
    OPPORTUNITY = 2,
    WEB_BOT = 3,
    WHATSAPP_BOT = 4,
}
